import {
  CLICK_SCAN,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT,
  SEARCH_SCANPACK_ORDER,
  SEARCH_SCANPACK_ORDER_FAIL,
  DETECT_ORDERS_DISCREPANCIES,
  DASHBOARD_DATA,
  RESET_SCANPACK_ORDER
} from '../constants';

const initialState = {};

const scanpackReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT:
      return {...state, updatedProduct: action.payload};
    case SEARCH_SCANPACK_ORDER:
      return {...state, searchOrder: action.payload, time: new Date()};
    case RESET_SCANPACK_ORDER:
      return {...state, searchOrder: null, time: new Date()};
    case SEARCH_SCANPACK_ORDER_FAIL:
      return {...state, searchOrder: action.payload, time: new Date()};
    case UPDATE_PRODUCT_FAIL:
      return {...state, searchOrder: action.payload, time: new Date()};
    case DETECT_ORDERS_DISCREPANCIES:
      return {...state, orders_data: action.payload, time: new Date()};
    case DASHBOARD_DATA:
      return {...state, dashboardData: action.payload, time: new Date()};

    default:
      return state;
  }
};

export default scanpackReducer;
