import AsyncStorage from '@react-native-async-storage/async-storage';
import Axios from 'axios';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import { View, StyleSheet, Picker, Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {GetBothSettings, GetGeneralSetting} from '../../actions/userAction';
import { Entypo } from '@expo/vector-icons';
import styles from '../../style/orderlist';
import { getSelectedOrderIds, getSelectedOrders } from '../../helpers/scanPackFunctions';

import useDropdown from './useDropdown';

const CustomDropdown = ({ options, onSelect, title, isOpen, toggleDropdown, handleOutsideClick, testID }) => {

  const { dropdownRef, handleSelectOption } = useDropdown(onSelect, isOpen, toggleDropdown);


  return (
    <View style={styles.dropdownContainer} ref={dropdownRef}>
      <TouchableOpacity testID={testID} onPress={toggleDropdown} style={styles.selectedOption}>
        <View style={{ flexDirection: 'row', alignItems: 'center', borderBottom: "1px solid #ddd" }}>
          <Text style={{ fontFamily: 'Poppins_600SemiBold', color: 'white' }}>{title}</Text>
          <Entypo name="chevron-down" size={18} color="white" style={{ marginLeft: 3 }} />
        </View>
      </TouchableOpacity>
      {isOpen && (
        <View style={styles.optionsStatusContainer}>
          {options.map((option, index) => (
            <TouchableOpacity
              testID={index}
              key={index}
              style={style.option}
              onPress={() => handleSelectOption(option)}>
              <Text style={{color: 'white'}}>{option.label}</Text>
            </TouchableOpacity>
          ))}
        </View>
      )}
    </View>
  );
};

function UpdateOrdersDropDowns  ({ updateOrderStatus, gridSelectionRef, skip, limit, statuses, redirectToProductDetail, alertBox, getOrderList, searchString, PresentFilterValues, resetSelectedOrders, getUnselectedItems, unselectedOrderItems,selectedRows }) {
  let url= '';
  const dispatch = useDispatch();
  const {bothSettings} = useSelector(state => state.user);
  const general_settings = bothSettings?.data?.data?.general_setting;
  const orders = gridSelectionRef?.selected
  let gridSelectionIdsArray = getSelectedOrderIds(selectedRows)

  const orders_id = gridSelectionIdsArray

  const dropdownOptions = {
    changeStatus: [
      { label: 'Awaiting', value: 'awaiting' },
      { label: 'Scanned', value: 'scanned' },
      { label: 'Service Issue', value: 'serviceissue' },
      { label: 'Cancelled', value: 'cancelled' },
    ],
    editAndExport: [
      { label: 'Delete', value: 'delete' },
      { label: 'Duplicate', value: 'duplicate' },
      { label: 'Export Orders (Standard)', value: 'items_list_standard_export' },
      { label: 'Export Consolidated SKU list', value: 'items_list_by_sku' }
    ],
    print: [
      { label: 'Shipping Label', value: 'shipping_label' },
      { label: 'Packing Slip', value: 'packing_slip' },
      { label: 'Pick List', value: 'pick_list' },
      { label: 'Pick List & Packing Slips', value: 'pick_list_packing_slips' },
      { label: 'Print Barcode', value: 'barcode_slip' },
    ],
  };


  const [openDropdown, setOpenDropdown] = useState(null);

  const handlePrintLabel = async () => {
    const userUrl = await AsyncStorage.getItem('url');
    if (gridSelectionRef.selected !== true && getSelectedOrders(selectedRows).length === 1) {
      const selected_order = orders_id[0];
      try {
        const url = (userUrl + '/orders/' + selected_order.id + '/print_shipping_label.json');
        const access_token = await AsyncStorage.getItem('access_token');
        const data = await Axios({
          method: 'get',
          url: url,
          headers: {Authorization: `Bearer ${access_token}`},
        });

        const error = data.data.error;

        if (data.status && error === "") {
          window.open(data.data.url);
        } else {
          if (error) {
            alertBox(error);
            console.error('Error:', error);
          }
          if (data.data.ss_label_order_data) {
            // debugger
            let current_order_item = Object.values(orders)[0].currentItem
            // const item = orders.find(item => cellProps.data.currentItem.order_info?.id === item.order_info?.id);
            redirectToProductDetail(current_order_item);
          }
        }
      } catch (error) {
        alertBox(error);
      }
    } else {
      alertBox('Please select a single order');
    }
  };

  const generateList = async(action) => {
    const userUrl = await AsyncStorage.getItem('url');
    let data = {
      filter: 'awaiting',
      order: 'DESC',
      limit: limit,
      offset: skip,
      app: 'app',
      count: 0,
      orderArray: orders_id,
      select_all: false,
      unselected: unselectedOrderItems || ""
    }

    if(gridSelectionRef.selected === true){
        data.select_all = true
      }

      if (action === "pick_list") {
        url = (`${userUrl}/orders/generate_pick_list.json`);
      } else if (action === "packing_slip") {
        // if (gridSelectionRef.selected === true) {
        //   let slipUrl = '';
        //   let lowerCaseStatuses = statuses.map(str => str?.toLowerCase());
        //   slipUrl = `${userUrl}/orders/generate_all_packing_slip?filter=${lowerCaseStatuses || 'all'}&sort=${""}&order=${'DESC'}`;
        //   window.open(slipUrl);
        // } else {
          url = (`${userUrl}/orders/generate_packing_slip.json`);
        // }
      } else if (action === 'items_list_standard_export') {
        const product_search_toggle = general_settings.search_by_product
        url = (`${userUrl}/orders/order_items_export.json?product_search_toggle=${product_search_toggle}&export_type=${"standard_order_export"}`);
      } else if (action === 'items_list_by_sku') {
        const product_search_toggle = general_settings.search_by_product
        url = (`${userUrl}/orders/order_items_export.json?product_search_toggle=${product_search_toggle}&export_type=${"by_sku"}`);
      } else if (action === 'barcode_slip') {
        let barcodeUrl = null;
        if (gridSelectionRef.selected === true) {
          url = (`${userUrl}/products/bulk_barcode_generation?ids=all&status=awaiting`);
        } else {
          let ids = "";
          orders_id.forEach(item => {
            ids = ids + "," + item.id;
          });

          if (orders.length > 20) {
            url = (`${userUrl}/products/bulk_barcode_generation?ids=${ids}`);
          } else {
            barcodeUrl = `${userUrl}/products/bulk_barcode_pdf.pdf?ids=${ids}`;
          }
        }
        if (barcodeUrl, action) {
          window.open(barcodeUrl);
        }
      }
      else if (action == "delete") {
        data.status = "";
        data.filters = PresentFilterValues;
        let lowerCaseStatuses = statuses.map(str => str?.toLowerCase()).join(',');
        data.filter = lowerCaseStatuses || 'all';
        data.search = searchString;
        url = userUrl + '/orders/delete_orders.json';
      } else if (action == "duplicate") {
        data.status = "";
        data.filters = PresentFilterValues;
        let lowerCaseStatuses = statuses.map(str => str?.toLowerCase()).join(',');
        data.filter = lowerCaseStatuses || 'all';
        // data.select_all = false
        data.search = searchString
        url = userUrl + '/orders/duplicate_orders.json';
      }
      postData(url, action, data)
    };

  const postData = async(url, action, data) => {
    const access_token = await AsyncStorage.getItem('access_token');
    if (url !== "") {
      try {
        const response = await Axios({
          method: 'post',
          url: url,
          headers: {Authorization: `Bearer ${access_token}`},
          data
        });
        if (action === "pick_list") {
          window.open(response.data.url);
        } else if (action === 'items_list_standard_export' ||  action === 'items_list_by_sku') {
          if (response.status && response.data.filename !== '') {
            window.open(response.data.filename);
          } else {
            console.log(response.data.messages);
          }
        }
        if (action === 'barcode_slip') {
          console.log(response.data.messages);
        }
        if (action === 'duplicate') {
          getOrderList(data);
        }
        else if (action == "delete") {
          getOrderList(data);
        }
        resetSelectedOrders();
      } catch (error) {
        alertBox(error);
      }
    }
  }

  const handleGenerate = (value) => {
    if (value === 'pick_list_packing_slips') {
      generate_orders_pick_list_and_packing_slip();
    } else if(value === 'shipping_label'){
      handlePrintLabel();
    }
    else {
      generateList(value);
    }
  };

  const handleValueChange = (itemValue) => {
    updateOrderStatus(itemValue);
  };

  const generate_orders_pick_list_and_packing_slip = () => {
    generateList("pick_list");
    generateList("packing_slip");
  };

  const toggleDropdown = (dropdownKey) => {
    setOpenDropdown(openDropdown === dropdownKey ? null : dropdownKey);
  };


  useEffect(() => {
    dispatch(GetBothSettings());
  }, []);

  const handleOutsideClick = () => {
    setOpenDropdown(null)
  };

  const renderDropdown = (options, functionCall, title, isOpen, toggleDropdown, testID) => (
    <CustomDropdown
      options={options}
      onSelect={functionCall}
      title={title}
      isOpen={isOpen}
      toggleDropdown={toggleDropdown}
      handleOutsideClick={handleOutsideClick}
      testID={testID}
    />
  );

  return (
    <View style={styles.container}>
      {renderDropdown(dropdownOptions.changeStatus, (value) => handleValueChange(value), "Change Status", openDropdown === 'dropdown1', () => toggleDropdown('dropdown1'), 'ChangeStatusBtn')}
      {renderDropdown(dropdownOptions.editAndExport, (value) => handleGenerate(value), "Edit & Export", openDropdown === 'dropdown2', () => toggleDropdown('dropdown2'), 'Edit&Export')}
      {renderDropdown(dropdownOptions.print, (value) => {handleGenerate(value)}, "Print", openDropdown === 'dropdown3', () => toggleDropdown('dropdown3'), 'Print')}
    </View>
  );
};


const style = StyleSheet.create({
  option: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    color: 'white',
    border: 'none'
  },
});

export default UpdateOrdersDropDowns;
