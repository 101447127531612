import React, {Component} from 'react';
import {
  View,
  Text,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
  ImageBackground,
  Dimensions,
  Image,
  ScrollView
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RescanPopup from './rescan_popup';
import {connect} from 'react-redux';
import NetInfo from '@react-native-community/netinfo';
import { GetOrderList, UpdateOrderStatus } from '../../actions/orderActions';
import {
  SearchScanpackOrder,
  ImportOrder,
  ImportOrderStatus,
  CheckImportOrderProcess,
  ResetSearchOrderData
} from '../../actions/scanpackAction';
import {GetBothSettings, UserInfo} from '../../actions/userAction';
import {ShowNotification} from '../../actions/menuActions';
import styles from '../../style/scanpack';
import CommonStyles from '../../style/commonStyles';
import PopUpModel from './pop_up_model';
import {LinearGradient} from 'expo-linear-gradient';
import {fontFamily} from '../../helpers/fontFamily';
import {FontAwesome} from '@expo/vector-icons';
import moment from 'moment';
import {ScanOrderAlreadyMsg} from '../notification';
import i18n from 'i18n-js';
import InterNetChecks from '../internateConnectionCheck';
import InerternetConnectionAlert from '../internetConnectionAlert';
import notReady from '../../../assets/sounds/not_ready.mp3';
import {ORDER_LIST} from '../../constants';
import _ from 'lodash';
import { fetchUser } from '../../actions/saveLogAction';
import { handleOrderReScan } from '../../helpers/scanPackFunctions';

const height = Dimensions.get('window').height;
let orderListSuccess = false;
let searchOrderSuccess = false;
let validateUser = false;
let messageShow = false;
let scannedAlert = false;
let importDates = false;
let attempts = 0;
var notReadySound = new Audio(notReady);
class ScanPack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowwidth: 0,
      reScanPopupCond: '',
      toolTipVisible: false,
      data: {
        input: '',
        state: 'scanpack.rfo',
        id: null,
        box_id: null,
        store_order_id: null,
        app: 'app'
      },
      popUpModelShow: false,
      reScanPopupShow: false,
      reScanOrderPopupShow: false,
      dataList: {
        filter: '',
        order: 'DESC',
        limit: '1',
        offset: '0',
        app: 'app',
        count: 0
      },
      orderDetail: null,
      rfoTitle: "Scan the Packing Slip of an Order you'd like to Pack.",
      time: new Date(),
      loading: false,
      newLoading: false,
      loaderTitle: '',
      userInfo: '',
      scan_pack_settings: null,
      quickScanToolTip: false,
      refreshToolTip: false,
      toolTipCheck: false,
      importOrderText: '',
      animatingType: false,
      cueOrderStatus: '',
      importButton: true,
      EtaText: '',
      ShowEtaText: false,
      showQuick: false,
      quickValue: '',
      showStatus: true,
      orderNumberInput: '',
      popInternet: true
      // closeModelQz: false,
    };
    this.inputFocus = React.createRef();
    this.searchOrder = this.searchOrder.bind(this);
    window.addEventListener('resize', this.update);
    // importDate = true
  }
  componentDidMount() {
    // this.setState({closeModelQz:this.props.route.params.alert})

    // setTimeout(()=>{

    //   this.setState({closeModelQz:!this.props.route.params.alert})
    // },2000)
    this.props.route.params.socketFun();
    this.props.UserInfo();
    this.props.GetBothSettings();
    this.props.ImportOrderStatus();
    this.setState({loading: true, loaderTitle: 'Loading...'});
    this.getOldestUnscannedOrder();
    validateUser = true;
    this.update();
    const sI = setInterval(() => {
      this.test();
    }, 2000);
    if (this.props.showNotification) {
      this.alertBox(
        this.props.isCodeFound
          ? i18n.t('scanpackItem.service_Issue_notification_one')
          : i18n.t('scanpackItem.service_Issue_notification'),
        true,
        true
      );
    }
    notReadySound.load();
    if (this.props.route.params.page === 'orderSearchList') {
      const orderNumber = this.props.route.params.orderNumber;
      this.searchOrder(orderNumber)
    }
  }
  update = () => {
    this.setState({
      windowwidth: window.innerWidth
    });
  };
  //According to the platform call the function
  getOrder = () => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      this.checkConnection('apiCall');
    } else {
      this.apiCall();
    }
  };
  /////////////Alert DialogBox open
  alertBox = (message, skip, messageTypeSuccess) => {
    // console.log("---000msg--", message)
    if (messageTypeSuccess === true) {
      this.setState({
        popUpModelShow: true,
        message: message,
        messageTypeSuccess: true
      });
    } else {
      let checkStatus =
        this.props?.searchOrder?.searchOrder?.data?.order[0]?.order_info
          ?.status;
      if (checkStatus === 'scanned') {
        this.setState({message: message, reScanPopupShow: true});
      }
    }
    setTimeout(
      function () {
        this.setState({
          popUpModelShow: false,
          message: '',
          messageTypeSuccess: false
        });
        this.props.ShowNotification({value: false, codeFound: false});
      }.bind(this),
      4000
    );
  };
  //Check the connection for the ios and android
  checkConnection = async (type, input) => {
    const connectionInfo = await NetInfo.fetch();
    if (connectionInfo.isConnected) {
      type === 'callShipmentOrder'
        ? this.callShipmentOrder(input)
        : this.apiCall();
    } else {
      this.setState({rfoTitle: 'Please check your internet connection'});
    }
  };
  //Call the api to fetch the data from backend
  apiCall = () => {
    let dataList = _.cloneDeep(this.state.dataList);
    dataList.oldestUnscanned = true;
    dataList.sort = 'order_date';
    dataList.order = 'ASC';
    setTimeout(() => {
      this.props.GetOrderList(dataList);
      orderListSuccess = true;
    }, 3000);
  };

  getOldestUnscannedOrder = () => {
    let dataList = _.cloneDeep(this.state.dataList);
    dataList.oldestUnscanned = true;
    dataList.sort = 'order_date';
    dataList.order = 'ASC';
    setTimeout(() => {
      this.props.GetOrderList(dataList);
      orderListSuccess = true;
    }, 3000);
  };

  checkEstimateFun = data => {
    // console.log("data here-->", data)
    data.import_items.map(estimate => {
      // console.log("estimate", estimate)
      if (estimate.progress !== null && estimate.progress.status !== false) {
        let storeName = estimate?.store_info?.name;
        if (estimate?.progress?.elapsed_time_remaining) {
          if (
            estimate?.progress?.elapsed_time_remaining.substring(0, 2) ==
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(3, 5) ==
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(6, 8) != '00'
          ) {
            this.setState({
              EtaText: `Estimated time remaining: ${estimate?.progress?.elapsed_time_remaining.substring(
                6,
                8
              )} seconds for store ${storeName}`
            });
          }
          if (
            estimate?.progress?.elapsed_time_remaining.substring(0, 2) ==
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(3, 5) !=
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(6, 8) != '00'
          ) {
            this.setState({
              EtaText: `Estimated time remaining: ${estimate?.progress?.elapsed_time_remaining.substring(
                3,
                5
              )} minutes ${estimate?.progress?.elapsed_time_remaining.substring(
                6,
                8
              )} seconds for store ${storeName}`
            });
          }
          if (
            estimate?.progress?.elapsed_time_remaining.substring(0, 2) ==
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(3, 5) !=
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(6, 8) == '00'
          ) {
            this.setState({
              EtaText: `Estimated time remaining: ${estimate?.progress?.elapsed_time_remaining.substring(
                3,
                5
              )} minutes for store ${storeName}`
            });
          }
          if (
            estimate?.progress?.elapsed_time_remaining.substring(0, 2) !==
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(3, 5) ==
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(6, 8) == '00'
          ) {
            this.setState({
              EtaText: `Estimated time remaining: ${estimate?.progress?.elapsed_time_remaining.substring(
                0,
                2
              )} hours for store ${storeName}`
            });
          }
          if (
            estimate?.progress?.elapsed_time_remaining.substring(0, 2) !==
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(3, 5) !=
              '00' &&
            estimate?.progress?.elapsed_time_remaining.substring(6, 8) == '00'
          ) {
            this.setState({
              EtaText: `Estimated time remaining: ${estimate?.progress?.elapsed_time_remaining.substring(
                0,
                2
              )} hours ${estimate?.progress?.elapsed_time_remaining.substring(
                3,
                5
              )} minutes for store ${storeName}`
            });
          }
          this.setState({ShowEtaText: true});
        } else {
          this.setState({EtaText: 'Estimated time remaining calculating'});
          this.setState({ShowEtaText: true});
        }
      }
    });
  };
  unauth = async () => {
    try {
      const username = await AsyncStorage.getItem('username');
      const tenent = await AsyncStorage.getItem('tenent');
      await AsyncStorage.clear();
      if (username) {
        await AsyncStorage.setItem('username', username);
      }
      await AsyncStorage.setItem('tenent', tenent);
      // this.redirect("SignIn");

      // Reload Window to Fix issues with Socket
      window.location.reload();

      this.props.navigation.navigate('SignIn', {
        api: 'importstatus',
        status: '401'
      });
    } catch (e) {}
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps?.importOrder?.importstatusError == '401' ||
      nextProps?.importOrder?.importdataError == '401' ||
      nextProps?.userInfo == '401' ||
      nextProps?.ordersList == '401' ||
      nextProps?.searchOrder.searchOrder == '401'
    ) {
      this.unauth();
    }
    // if (nextProps?.importOrder?.importdataError == '401') {
    //   this.unauth()
    // }
    // if (nextProps?.userInfo == '401') {
    //   this.unauth()
    // }
    // if (nextProps?.ordersList == '401') {
    //   this.unauth()
    // }
    // if (nextProps?.searchOrder.searchOrder == '401') {
    //   this.unauth()
    // }
    if (this.state.showStatus) {
      if (
        nextProps?.importOrder?.importstatusData?.summary?.import_info
          ?.status == 'cancelled' ||
        nextProps?.importOrder?.importstatusData?.summary?.import_info
          ?.status == 'completed'
      ) {
        if (
          nextProps.importOrder.importstatusData?.summary?.import_info
            ?.updated_at
        ) {
          this.setState({ShowEtaText: false});
          let currentdate = new Date();
          // console.log(moment(currentdate).format('mm'))
          let updatedate = new Date(
            nextProps.importOrder.importstatusData?.summary?.import_info?.updated_at
          );
          // console.log(updatedate.getMonth())
          let status =
            nextProps?.importOrder?.importstatusData?.summary?.import_info
              ?.status;

          if (currentdate.getFullYear() !== updatedate.getFullYear()) {
            let days = currentdate - updatedate;
            // console.log("----days by update date", (days / (1000 * 60 * 60 * 24)).toFixed(0))
            let d = (days / (1000 * 60 * 60 * 24)).toFixed(0);
            let daycheck = d == 1 ? `${d} day` : `${d} days`;
            this.setState({importOrderText: `${status} ${daycheck} ago`});
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() == updatedate.getMonth() &&
            currentdate.getDay() !== updatedate.getDay()
          ) {
            let days = currentdate - updatedate;
            let d = (days / (1000 * 60 * 60 * 24)).toFixed(0);
            let daycheck = d == 1 ? `${d} day` : `${d} days`;
            this.setState({importOrderText: `${status} ${daycheck} ago`});
            // console.log("----days by update date", (days / (1000 * 60 * 60 * 24)).toFixed(0))
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() !== updatedate.getMonth()
          ) {
            let month = currentdate.getMonth() - updatedate.getMonth();
            let monthcheck = month == 1 ? `${month} month` : `${month} months`;
            this.setState({importOrderText: `${status} ${monthcheck} ago`});

            //  console.log("----days by update date", days)
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() == updatedate.getMonth() &&
            currentdate.getDay() == updatedate.getDay() &&
            currentdate.getHours() !== updatedate.getHours()
          ) {
            let hour =
              moment(currentdate).format('HH') -
              moment(updatedate).format('HH');
            let min =
              moment(currentdate).format('mm') -
              moment(updatedate).format('mm');
            if (min.toString().includes('-')) {
              hour = hour - 1;
              let minc = 60 - parseInt(moment(updatedate).format('mm'));
              let ss = parseInt(moment(currentdate).format('mm')) + minc;
              min = ss;
            }
            let hourCheck =
              hour == 0 ? '' : hour == 1 ? `${hour} hour` : `${hour} hours`;
            let minCheck =
              min == '0' ? '' : min == 1 ? `${min} minute` : `${min} minutes`;
            this.setState({
              importOrderText: `${status} ${hourCheck} ${minCheck} ago`
            });
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() == updatedate.getMonth() &&
            currentdate.getDay() == updatedate.getDay() &&
            currentdate.getHours() == updatedate.getHours() &&
            currentdate.getMinutes() !== updatedate.getMinutes()
          ) {
            let minut =
              moment(currentdate).format('mm') -
              moment(updatedate).format('mm');
            let minut1 = moment(currentdate).format('m:ss');
            let min2 = moment(updatedate).format('m:ss');
            let sec =
              moment(currentdate).format('ss') -
              moment(updatedate).format('ss');
            if (sec.toString().includes('-')) {
              minut = minut - 1;
              let se = 60 - parseInt(moment(updatedate).format('ss'));
              let ss = parseInt(moment(currentdate).format('ss')) + se;
              sec = ss;
            }
            let minCheck =
              minut == 0
                ? ''
                : minut == 1
                ? `${minut} minute`
                : `${minut} minutes`;
            let secCheck =
              sec == '0' ? '' : sec == 1 ? `${sec} second` : `${sec} seconds`;
            this.setState({
              importOrderText: `${status} ${minCheck} ${secCheck} ago`
            });
            // console.log("----days by update date", minut)
          }
          if (
            currentdate.getFullYear() == updatedate.getFullYear() &&
            currentdate.getMonth() == updatedate.getMonth() &&
            currentdate.getDay() == updatedate.getDay() &&
            currentdate.getHours() == updatedate.getHours() &&
            currentdate.getMinutes() == updatedate.getMinutes() &&
            currentdate.getSeconds() !== updatedate.getSeconds()
          ) {
            // let minut = moment(currentdate).format('mm') - moment(updatedate).format('mm')
            let sec =
              moment(currentdate).format('ss') -
              moment(updatedate).format('ss');
            let secCheck = sec == 1 ? `${sec} second` : ` ${sec} seconds`;
            this.setState({importOrderText: `${status} ${secCheck} ago`});
            // console.log("----days by update date", minut)
          }
        }
        this.setState({
          animatingType: false,
          importButton: true
        });
      }
      if (
        nextProps?.importOrder?.importstatusData?.summary?.import_info
          ?.status == 'in_progress'
      ) {
        this.setState({importButton: false});
        this.checkEstimateFun(nextProps.importOrder.importstatusData?.summary);
        if (
          nextProps.importOrder.importstatusData?.summary?.import_info
            ?.created_at
        ) {
          let currentdate = new Date();
          // console.log(moment(currentdate).format('h:mm'))
          let createdate = new Date(
            nextProps.importOrder.importstatusData?.summary?.import_info?.created_at
          );
          // console.log(updatedate.getMonth())
          let status = 'The last import was stared';
          // nextProps?.importOrder?.importstatusData?.summary?.import_info?.status
          if (currentdate.getFullYear() !== createdate.getFullYear()) {
            let days = currentdate - createdate;
            let d = (days / (1000 * 60 * 60 * 24)).toFixed(0);
            let daycheck = d == 1 ? `${d} day` : `${d} days`;
            this.setState({importOrderText: `${status} ${daycheck} ago`});

            // console.log("----days by update date", (days / (1000 * 60 * 60 * 24)).toFixed(0))
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() == createdate.getMonth() &&
            currentdate.getDay() !== createdate.getDay()
          ) {
            let days = currentdate - createdate;
            let d = (days / (1000 * 60 * 60 * 24)).toFixed(0);
            let daycheck = d == 1 ? `${d} day` : `${d} days`;
            this.setState({importOrderText: `${status} ${daycheck} ago`});
            // console.log("----days by update date", (days / (1000 * 60 * 60 * 24)).toFixed(0))
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() !== createdate.getMonth()
          ) {
            let month = currentdate.getMonth() - createdate.getMonth();
            this.setState({importOrderText: `${status} ${month} months ago`});

            // console.log("----days by update date", days)
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() == createdate.getMonth() &&
            currentdate.getDay() == createdate.getDay() &&
            currentdate.getHours() !== createdate.getHours()
          ) {
            let hour =
              moment(currentdate).format('H') - moment(createdate).format('H');
            let min =
              moment(currentdate).format('mm') -
              moment(createdate).format('mm');
            if (min.toString().includes('-')) {
              hour = hour - 1;
              let minc = 60 - parseInt(moment(createdate).format('mm'));
              let ss = parseInt(moment(currentdate).format('mm')) + minc;
              min = ss;
            }
            let hourCheck =
              hour == 0 ? '' : hour == 1 ? `${hour} hour` : `${hour} hours`;
            let minCheck =
              min == '0' ? '' : min == 1 ? `${min} minute` : `${min} minutes`;
            this.setState({
              importOrderText: `${status} ${hourCheck} ${minCheck} ago`
            });
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() == createdate.getMonth() &&
            currentdate.getDay() == createdate.getDay() &&
            currentdate.getHours() == createdate.getHours() &&
            currentdate.getMinutes() !== createdate.getMinutes()
          ) {
            let minut =
              moment(currentdate).format('mm') -
              moment(createdate).format('mm');
            let secd =
              moment(currentdate).format('ss') -
              moment(createdate).format('ss');
            if (secd.toString().includes('-')) {
              minut = minut - 1;
              let se = 60 - parseInt(moment(createdate).format('ss'));
              let ss = parseInt(moment(currentdate).format('ss')) + se;
              secd = ss;
            }
            let minCheck =
              minut == 0
                ? ''
                : minut == 1
                ? `${minut} minute`
                : `${minut} minutes`;
            let secCheck =
              secd == '0'
                ? ''
                : secd == 1
                ? `${secd} second`
                : `${secd} seconds`;

            this.setState({
              importOrderText: `${status} ${minCheck} ${secCheck} ago`
            });
          }
          if (
            currentdate.getFullYear() == createdate.getFullYear() &&
            currentdate.getMonth() == createdate.getMonth() &&
            currentdate.getDay() == createdate.getDay() &&
            currentdate.getHours() == createdate.getHours() &&
            currentdate.getMinutes() == createdate.getMinutes() &&
            currentdate.getSeconds() !== createdate.getSeconds()
          ) {
            let sec =
              moment(currentdate).format('ss') -
              moment(createdate).format('ss');
            // console.log("here--->>>sec", sec)
            let secCkeck = sec == 1 ? `${sec} second` : `${sec} seconds`;
            this.setState({importOrderText: `${status} ${secCkeck} ago`});
          }
        }
      }
      if (
        nextProps?.importOrder?.importstatusData?.summary?.import_info
          ?.status == 'not_started'
      ) {
        // console.log("started -->", nextProps.importOrder.importstatusData.summary)
        this.setState({importButton: true});
        let currentdate = new Date();
        let createdate = new Date(
          nextProps?.importOrder?.importstatusData?.summary?.import_info?.created_at
        );
        if (
          currentdate.getFullYear() == createdate.getFullYear() &&
          currentdate.getMonth() == createdate.getMonth() &&
          currentdate.getDay() == createdate.getDay() &&
          currentdate.getHours() !== createdate.getHours()
        ) {
          let hour =
            moment(currentdate).format('H') - moment(createdate).format('H');
          let min =
            moment(currentdate).format('mm') - moment(createdate).format('mm');
          if (min.toString().includes('-')) {
            hour = hour - 1;
            let minc = 60 - parseInt(moment(createdate).format('mm'));
            let ss = parseInt(moment(currentdate).format('mm')) + minc;
            min = ss;
          }
          let hourCheck =
            hour == 0 ? '' : hour == 1 ? `${hour} hour` : `${hour} hours`;
          let minCheck =
            min == '0' ? '' : min == 1 ? `${min} minute` : `${min} minutes`;
          this.setState({
            importOrderText: `Import is started ${hourCheck} ${minCheck} ago`
          });
        }
        if (
          currentdate.getFullYear() == createdate.getFullYear() &&
          currentdate.getMonth() == createdate.getMonth() &&
          currentdate.getDay() == createdate.getDay() &&
          currentdate.getHours() == createdate.getHours() &&
          currentdate.getMinutes() !== createdate.getMinutes()
        ) {
          let min =
            moment(currentdate).format('mm') - moment(createdate).format('mm');
          let sec =
            moment(currentdate).format('ss') - moment(createdate).format('ss');
          // console.log("-->>>>>",createdate,secCreate,min,sec,moment(currentdate).format('mm'),moment(currentdate).format('ss'))
          if (sec.toString().includes('-')) {
            min = min - 1;
            let se = 60 - parseInt(moment(createdate).format('ss'));
            let ss = parseInt(moment(currentdate).format('ss')) + se;
            sec = ss;
          }
          let minCheck =
            min == 0 ? '' : min == 1 ? `${min} minute` : `${min} minutes`;
          let secCheck =
            sec == '0' ? '' : sec == 1 ? `${sec} second` : `${sec} seconds`;
          this.setState({
            importOrderText: `Import is started ${minCheck} ${secCheck} ago`
          });
        }
        if (
          currentdate.getFullYear() == createdate.getFullYear() &&
          currentdate.getMonth() == createdate.getMonth() &&
          currentdate.getDay() == createdate.getDay() &&
          currentdate.getHours() == createdate.getHours() &&
          currentdate.getMinutes() == createdate.getMinutes() &&
          currentdate.getSeconds() !== createdate.getSeconds()
        ) {
          let sec =
            moment(currentdate).format('ss') - moment(createdate).format('ss');
          let secCheck = sec == 1 ? `${sec} second` : `${sec} seconds`;
          this.setState({
            importOrderText: `Import is started ${secCheck} ago`
          });
        }
        this.setState({animatingType: false});
      }
    }
  }
  componentDidUpdate(pP) {
    // console.log("------===+++",pP,this.props)

    // if(importDates == true){
    //   console.log("props scan",this.props.importOrder.importstatusData)
    //   importDates = false
    // }
    if(this.props.orderList?.orderList){
      this.apiCall()
      this.props.fetchUser(false)
    }
    if (
      orderListSuccess === true &&
      this.props &&
      this.props.ordersList &&
      this.props.ordersList !== this.state.list
    ) {
      orderListSuccess = false;
      let list = this.props.ordersList;

      let orderShow = list?.orders?.length === 0 ? true : false;
      this.setState({
        scan_pack_settings: list?.scan_pack_settings,
        general_settings: list?.general_settings,
        rfoTitle:
          list?.orders?.length > 0
            ? list && list?.orders[0] && list?.orders[0]?.ordernum
            : null,
        //rfoTitle: list.orders.length > 0 ? list && list.orders[0] && list.orders[0].ordernum : "Please scan an order to continue." ,
        orderDetail: list && list.orders && list.orders[0],
        loading: false,
        list: this.props.ordersList,
        unClick: orderShow
      });
      if (list?.scan_pack_settings?.scan_by_packing_slip) {
        this.setState({cueOrderStatus: i18n.t('scanPack.packing_slip')});
      }
      if (list?.scan_pack_settings?.scan_by_packing_slip_or_shipping_label) {
        this.setState({
          cueOrderStatus: i18n.t('scanPack.shippingLable_or_packingSlip')
        });
      }
      if (list?.scan_pack_settings?.scan_by_shipping_label) {
        this.setState({cueOrderStatus: i18n.t('scanPack.shippingLable')});
      }
    }
    if (
      messageShow === false &&
      this.props &&
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.showScannedMessage
    ) {
      this.alertBox(i18n.t('scanPack.already_scanned'), true, false);
      // notReadySound.play();
      this.setState({loading: true, loaderTitle: 'Loading...'});
      this.getOrder();
      messageShow = true;
    }
    if (
      messageShow === false &&
      this.props &&
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.showCancelledMessage
    ) {
      this.alertBox(i18n.t('scanPack.already_cancelled'), true, true);
      this.setState({loading: true, loaderTitle: 'Loading...'});
      this.getOrder();
      messageShow = true;
    }

    if (
      scannedAlert === false &&
      this.props &&
      this.props.route &&
      this.props.route.params &&
      this.props.route.params.showScannedMessageAlert
    ) {
      this.alertBox(i18n.t('scanPack.scanned_one_pass'), true, true);
      // this.setState({ loading: true, loaderTitle: "Loading..." })
      // this.getOrder()
      scannedAlert = true;
    }
    //Search Order Data
    if (
      searchOrderSuccess === true &&
      this.props &&
      this.props.searchOrder &&
      this?.props?.searchOrder?.time > this?.state?.apiCallTime &&
      this?.props?.searchOrder !== this?.state?.oldOne
    ) {
      let searchResult =
        this?.props?.searchOrder && this?.props?.searchOrder?.searchOrder;
      let order =
        searchResult &&
        searchResult?.data?.order &&
        searchResult?.data?.order[0];
      if (searchResult) {
        if (searchResult?.matched && searchResult?.error_messages.length === 0) {
          if (searchResult?.data) {
            this.orderDetails(order);
            searchOrderSuccess = false;
          } else {
            if (searchResult.data.status === 'scanned') {
              let message = `Order ${searchResult.data.order_num} has already been scanned.`;
              this.statusFunction(message);
              this.setState({unClick: true});
            }
          }
        } else {
          this.alertBox(searchResult?.error_messages, true, true);
          notReadySound.play();
          this.setState({unClick: true});
          searchOrderSuccess = false;
        }
        this.setFocus();
        let data = this.state.data;
        data['input'] = '';
        this.setState({oldOne: this.props.searchOrder, data, loading: false});
      }
    }
    if (
      validateUser &&
      this.props &&
      this.props.userInfo &&
      this.props.userInfo !== this.state.userInfo
    ) {
      if (this.props.userInfo.is_active === false) {
        validateUser = false;
        this.props.UserInfo(false);
        this.redirect();
      }
    }
    !this.props.isOpen && this.inputFocus.current.focus();
  }
  //////////// For Close Alert
  closeAlert = () => {
    this.setState({popUpModelShow: false});
    this.props.ShowNotification({value: false, codeFound: false});
  };
  closePopup = () => {
    this.setState({reScanPopupShow: false});
  };
  redirectToAnother = () => {
    this.setState({reScanPopupShow: false});
    this.setState({reScanOrderPopupShow: true});
  };
  closePopupRescan = () => {
    this.setState({reScanOrderPopupShow: false});
  };
  awaitOrderDataFun = async parameter => {
    const data = {
      filter: 'all',
      inverted: false,
      limit: 20,
      offset: 0,
      order: 'DESC',
      orderArray: [{id: this.state?.reScanPopupCond?.id}],
      product_search_toggle: 'true',
      reallocate_inventory: false,
      search: '',
      select_all: false,
      sort: '',
      status: 'awaiting',
      pull_inv: parameter,
      on_ex: 'on GPX'
    };
    this.props.ResetSearchOrderData();
    this.props.UpdateOrderStatus(data, this.callBack);
    await AsyncStorage.setItem('statusChange', 'pending')
  };
  setStateCallback = (changes) => {
    this.setState(changes);
  };
  callBack = async () => {
    handleOrderReScan({
      searchOrder: this.searchOrder,
      orderNumberInput: this.state.orderNumberInput,
      closePopupRescan: this.closePopupRescan,
      setState: this.setStateCallback,
      maxAttempts: 3,
    });
  };

  redirect = async () => {
    // await AsyncStorage.removeItem("access_token")
    await AsyncStorage.clear();
    this.props.navigation.navigate('SignIn', {
      status: 'Inactive',
      time: new Date()
    });
    this.setState({userInfo: this.props.userInfo});
  };
  //After the apis call update the status (scanned , order  not present)
  statusFunction = message => {
    let data = this.state.data;

    data['input'] = '';
    this.setState({rfoTitle: message, data});
  };

  //To set the focus
  setFocus = () => {
    this.inputFocus.current.focus();
  };

  //Redirect to the scanpackItem page with order details
  orderDetails = order => {
    const order_data = order?.scan_hash.data.order;
    this.setState({
      reScanPopupCond: this.props?.searchOrder?.searchOrder?.data?.order[0]
    });
    if (
      order &&
      !order_data.unscanned_items[0] &&
      order_data.status != 'scanned' &&
      !order_data.scanned_items[0]
    ) {
      this.alertBox(i18n.t('scanPack.empty_order'), true, true);
      this.setState({loading: true, loaderTitle: 'Loading...'});
      this.getOrder();
      return;
    }
    if (order && order.scan_hash && order.scan_hash.data && order?.order_info.status === 'awaiting') {
      // console.log("------->>>>>>>>>>>>>>>>")
      let packingcamPage =
        order.scan_hash.data.order.increment_id ==
        this?.props?.route?.params?.order_in
          ? this?.props?.route?.params?.page
          : '';
      this.setState({showQuick: false});
      this.props.navigation.navigate('ScanPackItem', {
        data: order.scan_hash.data,
        userdata: this?.props?.userInfo,
        packingcamPage: packingcamPage
      });
      messageShow = false;
    }
    else if (order?.order_info.status === 'scanned') {
      this.setState({
        reScanPopupShow: true
      });
    }
  };
  //Check the connection for mobile device before call the api
  searchOrder = input => {
    this.setState({loading: true, loaderTitle: 'Loading...', orderNumberInput: input});
    if (input !== '') {
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        this.checkConnection('callShipmentOrder', input);
      } else {
        this.callShipmentOrder(input);
      }
    }
  };
  //Api to call the search order
  callShipmentOrder = input => {
    // console.log("check input here",input)
    let shipmentOrder = this.state.data;
    shipmentOrder['input'] = input;
    if (input !== '') {
      shipmentOrder.input = input;
      this.props.SearchScanpackOrder(shipmentOrder);
      searchOrderSuccess = true;
      let apiCallTime = new Date();
      this.setState({apiCallTime});
    }
  };
  //Handle the search field input
  handleChange = (name, value) => {
    let order = this.state.data;
    if (name === 'searchOrder') {
      order['input'] = value;
    }
    if (this?.props?.userInfo?.role?.import_orders) {
      if (value === 'QUICKSCAN') {
        this.setState({showQuick: true});
        this.callShipmentOrder(this.state.rfoTitle);
      }
    }
    this.setState({order});
  };
  importorderFun = () => {
    this.setState({
      importOrderText:
        'Import starting. Please click refresh in a moment to check status.'
    });
    this.setState({animatingType: true});
    this.setState({showStatus: false});
    this.setState({importButton: false});
    this.props.ImportOrder();
  };
  // importTextFun = (text) =>{
  //   this.setState({importOrderText : text })

  // }
  componentWillUnmount() {
    this.props.GetOrderList(this.state.dataList, false);
    this.setState({importOrderText: ''});
  }
  drawerClose = () => {
    this.props.route.params.closeDrawer();
  };
  refreshStatus = () => {
    this.props.ImportOrderStatus();
    this.setState({showStatus: true, importButton: true});
  };
  tooltipFun = () => {
    this.setState({toolTipCheck: !this.state.toolTipCheck});
    this.setState({quickScanToolTip: !this.state.quickScanToolTip});
  };
  refreshTooltipFun = () => {
    this.setState({refreshToolTip: !this.state.refreshToolTip});
  };
  test = async () => {
    let num = await InterNetChecks();
    if (num !== true) {
      this.setState({popInternet: false});
    } else {
      this.setState({popInternet: true});
    }
  };
  closeRescanPopup = () => {
    this.setState({reScanPopupShow: false});
  };

  handleOrderImport = () => {
    this?.props?.userInfo?.role?.import_orders &&
    this.state.popInternet === true && (
      this.importorderFun()
    )
  }
  render() {
    return (
      <>
        {/* {this.props.route.params.alert &&<AlertBoxSaving name={i18n.t('directprinting.printComplete')}  closeAlert ={ ()=>{this.closeAlert()}}/>} */}
        <View
          style={{flex: 1, zIndex: '99'}}
          testID="closeTooltip"
          onStartShouldSetResponder={() => {
            this.drawerClose(),
              this.state.toolTipCheck ? this.tooltipFun() : <></>;
            this.state.refreshToolTip ? this.refreshTooltipFun() : <></>;
          }}>
          {
            // Notification Pop Up for messages
            (this.state.popUpModelShow || this.props.showNotification) && (
              <PopUpModel
                closeAlert={this.closeAlert.bind(this)}
                message={this.state.message}
                messageTypeSuccess={
                  this.state.messageTypeSuccess ? true : false
                }
              />
            )
          }

          {this.state.reScanPopupCond && !this.props?.isLoadingActive ? (
            <RescanPopup
              headingmessage="This order has already been scanned"
              subHeading="How would you like to proceed?"
              cancelButton="Cancel"
              rePrintBtn="Re-Print Shipping Label"
              reScanBtn="Re-Scan This Order"
              closePopup={this.closePopup.bind(this)}
              redirectToAnother={this.redirectToAnother.bind(this)}
              reScanOrderPopupShow={this.state.reScanOrderPopupShow}
              reScanPopupShow={this.state.reScanPopupShow}
              closePopupRescan={this.closePopupRescan.bind(this)}
              awaitOrderDataFun={parameter => this.awaitOrderDataFun(parameter)}
              newProps={this.props}
              navigation={this.props?.navigation}
              basicInfo={this.props?.searchOrder?.searchOrder?.data?.order?.[0]}
              generalSetting={this.props?.ordersList?.general_settings}
              userRole={this.props?.userInfo}
              newState={this.state.reScanPopupCond}
              isLoadingHere={this.props?.isLoadingActive}
              ssLabelData={this.props?.ssLabelData}
            />
          ) : (
            <></>
          )}
          {this.state.popInternet === false ? (
            <View style={{position: 'absolute', zIndex: 99, top: '-10%'}}>
              <InerternetConnectionAlert />
            </View>
          ) : null}
          <ImageBackground
            style={{flex: 1}}
            source={require('././../../../assets/background.png')}>
            {/* <View onStartShouldSetResponder={() => this.nav()}> */}
            <ScrollView
              contentContainerStyle={{flex: 1}}
              style={{position: 'relative'}}>
              <ImageBackground
                style={CommonStyles.quickScanImage}
                source={require('././../../../assets/scaning.png')}
                resizeMode="contain">
                <Text
                  style={[
                    CommonStyles.ScanText,
                    {lineHeight: this.state.windowwidth < 768 ? 55 : 90}
                  ]}>
                  Scan
                </Text>
                <Text style={[CommonStyles.lastImportTextOne, {marginTop: 0}]}>
                  {this?.state?.cueOrderStatus}
                </Text>

                <View
                  style={[
                    CommonStyles.searchContainer,
                    {width: this.state.windowwidth > 900 ? '30%' : '95%'}
                  ]}>
                  <TextInput
                    name="searchOrder"
                    autoFocus={true}
                    dataSet={{componentName: 'searchOrder'}}
                    placeholder="Ready for Order Scan"
                    ref={this.inputFocus}
                    value={
                      this.state.showQuick ? 'QUICKSCAN' : this.state.data.input
                    }
                    onChangeText={text => {
                      this.handleChange('searchOrder', text);
                    }}
                    onSubmitEditing={() => {
                      if (this.state.data.input === '') return;
                      this.searchOrder(
                        this.state.data && this.state.data.input
                      );
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        if (this.state.data.input === '') return;
                        this.searchOrder(
                          this.state.data && this.state.data.input
                        );
                      }
                    }}
                    style={CommonStyles.scanInput}
                  />
                  <TouchableOpacity
                    testID="rfoSearchBtn"
                    style={{paddingVertical: 4, paddingHorizontal: 5}}
                    onPress={() => {
                      if (this.state.data.input === '') {
                        this.handleOrderImport();
                        return;
                      }
                      this.searchOrder(
                        this.state.data && this.state.data.input
                      );
                    }}>
                    <i
                      className="icon-search"
                      style={{
                        color: 'rgb(172,187,199)',
                        fontSize: '23px'
                      }}></i>
                  </TouchableOpacity>
                </View>
                {(this.state.newLoading || this.props.isLoadingActive) && (
                  <View
                    style={{
                      position: 'fixed',
                      left: 0,
                      right: 0,
                      height: '100%',
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#000',
                      opacity: '60%',
                      zIndex: 2147483647
                    }}>
                    <ActivityIndicator size="large" color="#fff" />
                    <Text
                      style={{
                        fontSize: 13,
                        fontWeight: 500,
                        textAlign: 'center',
                        color: '#fff'
                      }}>
                      Loading...
                    </Text>
                  </View>
                )}
                {this.state.loading ? (
                  <View style={{flex: 1, justifyContent: 'center'}}>
                    {/* Activity Indicator Shown  */}
                    <ActivityIndicator size="large" color="#336599" />
                  </View>
                ) : (
                  <>
                    {this.state.error ? (
                      <Text style={styles.text}>{this.state.error}</Text>
                    ) : null}
                  </>
                )}
                <View style={CommonStyles.quickScanAlignmentOne}>
                  <LinearGradient
                    colors={['#95abbf', '#516b83']}
                    style={CommonStyles.quickScanRfo}>
                    <View />
                    <TouchableOpacity
                      testID="quickSearch"
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                      dataSet={{componentName: 'quickSearch'}}
                      onPress={() => {
                        if (
                          this.state.rfoTitle &&
                          this.state.popInternet === true
                        ) {
                          this?.props?.userInfo?.role?.access_scanpack &&
                            this.searchOrder(this.state.rfoTitle);
                        }
                      }}>
                      <View style={{alignItems: 'center'}}>
                        <Text
                          testID="quickScan"
                          style={CommonStyles.quickScanText}>
                          Quick-Scan
                        </Text>
                        {!this.state.loading ? (
                          <Text style={CommonStyles.quickScanLoadingText}>
                            {this.state.rfoTitle
                              ? this.state.rfoTitle
                              : 'No orders were found in the awaiting orders list.'}
                          </Text>
                        ) : null}
                      </View>
                      {/* <FontAwesome name="double-arrow" size={24} color="black" /> */}
                      {/* <FontAwesome name="double-arrow" size={28} color="rgb(119, 143, 165)" /> */}
                      <i
                        className="icon-right-circled"
                        style={{
                          fontSize: 24,
                          color: 'white',
                          marginTop: 12
                        }}></i>
                    </TouchableOpacity>
                  </LinearGradient>
                  <TouchableOpacity
                    testID="tooltipFun"
                    onPress={() => this.tooltipFun()}>
                    <i
                      className="icon-info-circled"
                      style={{
                        color: 'rgb(119,143,165)',
                        fontSize: '24px',
                        marginLeft: '10px'
                      }}></i>
                    {/* <FontAwesome name="info-circle" size={20} style={{ marginLeft: 10 }} color="rgb(119, 143, 165)" /> */}
                    {/* <Image source={require('././../../../assets/notificationicon.png')} style={{ height: 15, width: 15, marginLeft: 10 }} /> */}
                  </TouchableOpacity>
                </View>
                {this.state.quickScanToolTip && (
                  <LinearGradient
                    testID="quickScanToolTip"
                    locations={[0, 1]}
                    colors={['#8a9daf', '#d7e1e9']}
                    style={CommonStyles.quickScanTooltipContainer}>
                    <Text style={CommonStyles.quickScanTextOne}>
                      Click the Quick-Scan button or scan a barcode with the
                      value QUICKSCAN to automatically display the oldest
                      unscanned order for verification.
                    </Text>
                    <View style={CommonStyles.quickScanTooltipContainerOne} />
                  </LinearGradient>
                )}
                {this.props.importOrder.importstatusData?.summary?.import_info
                  ?.status == 'cancelled' ? (
                  <View style={{marginTop: 50}}>
                    <Text style={CommonStyles.lastImportText}>
                      {/* Import  Is {String(this.props?.importOrder?.time)} */}
                      The last import was {this.state.importOrderText}
                      {/* {moment(this.props.importOrder.importstatusData?.summary?.import_info?.updated_at).format('MMMM Do YYYY, h:mm:ss a')} */}
                    </Text>
                  </View>
                ) : this.props.importOrder.importstatusData?.summary
                    ?.import_info?.status == 'completed' ? (
                  <View
                    style={{marginTop: this.state.windowwidth < 768 ? 10 : 50}}>
                    <Text
                      style={[
                        CommonStyles.lastImportText,
                        {marginBottom: this.state.windowwidth < 768 && -30}
                      ]}>
                      {/* Import  Is {String(this.props?.importOrder?.time)} */}
                      The last import was {this.state.importOrderText}
                      {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                    </Text>
                  </View>
                ) : // this.props.importOrder.importstatusData?.summary?.import_info?.status == "Import is in progress" ?
                // (<View style={{ marginTop: 50 }}>
                //   <Text style={{ color: '#8da4b8', fontFamily: fontFamily.font300, marginBottom: 7, fontSize: 16 }}>
                //     {/* Import  Is {String(this.props?.importOrder?.time)} */}
                //     Last import {this.state.importOrderText}
                //     {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                //   </Text>
                // </View>)
                // :
                this.props?.importOrder?.importstatusData?.summary?.import_info
                    ?.status == 'not_started' ? (
                  <View style={CommonStyles.quickScanAlignment}>
                    <Text style={CommonStyles.lastImportText}>
                      {/* Import  Is {String(this.props?.importOrder?.time)} */}
                      {this.state.importOrderText}
                      {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                    </Text>
                    {this?.state?.ShowEtaText && (
                      <Text style={CommonStyles.lastImportText}>
                        {/* Import  Is {String(this.props?.importOrder?.time)} */}
                        {this.state.EtaText}
                        {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                      </Text>
                    )}
                  </View>
                ) : this.props?.importOrder?.importstatusData?.summary
                    ?.import_info?.status == 'in_progress' ? (
                  <View style={CommonStyles.quickScanAlignment}>
                    <Text style={CommonStyles.lastImportText}>
                      {/* Import  Is {String(this.props?.importOrder?.time)} */}
                      {this.state.importOrderText}
                      {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                    </Text>
                    {this?.state?.ShowEtaText && (
                      <Text style={CommonStyles.lastImportText}>
                        {/* Import  Is {String(this.props?.importOrder?.time)} */}
                        {this.state.EtaText}
                        {/* {this.props.importOrder.importstatusData?.summary?.import_info?.updated_at} */}
                      </Text>
                    )}
                  </View>
                ) : (
                  <View></View>
                )}
                <View
                  style={[CommonStyles.importButtonRfo, {alignSelf: 'center'}]}>
                  {this?.state?.importButton && (
                    <TouchableOpacity
                      dataSet={{componentName: 'importButton'}}
                      onPress={this.handleOrderImport}>
                      <LinearGradient
                        colors={['#95abbf', '#516b83']}
                        style={[CommonStyles.quickScanContainer2]}>
                        <Text style={[CommonStyles.reFreshText, {width: 69}]}>
                          Import Now
                        </Text>
                        <View style={{width: 4, height: 21}} />
                        <i
                          className="icon-down-circled"
                          style={{
                            fontSize: 24,
                            color: 'white',
                            marginTop: 2
                          }}></i>
                      </LinearGradient>
                    </TouchableOpacity>
                  )}
                  {this.props?.importOrder?.importdata?.error_messages &&
                    this.state.animatingType !== false && (
                      <View style={{marginTop: 20}}>
                        <ActivityIndicator
                          size="large"
                          color="#95abbf"
                          animating={this.state.animatingType}
                        />
                      </View>
                    )}
                  <TouchableOpacity
                    dataSet={{componentName: 'refreshButton'}}
                    onPress={() =>
                      this?.props?.userInfo?.role?.import_orders &&
                      this.state.popInternet === true ? (
                        this.refreshStatus()
                      ) : (
                        <></>
                      )
                    }>
                    <LinearGradient
                      colors={['#95abbf', '#516b83']}
                      style={[CommonStyles.quickScanContainer2, {width: 93}]}>
                      <Text style={CommonStyles.reFreshText}>Refresh</Text>
                      <View style={{width: 4, height: 21}} />
                      <i
                        className="icon-arrows-cw"
                        style={{
                          fontSize: 24,
                          color: 'white',
                          marginTop: 2
                        }}></i>
                    </LinearGradient>
                  </TouchableOpacity>
                  <TouchableOpacity
                    testID="refreshTooltipFun"
                    onPress={() => this.refreshTooltipFun()}>
                    <i
                      className="icon-info-circled"
                      style={{
                        color: 'rgb(119,143,165)',
                        fontSize: '24px',
                        marginTop: '35px',
                        position: 'absolute'
                      }}></i>
                    {this.state.refreshToolTip && (
                      <LinearGradient
                        testID="refreshToolTip"
                        locations={[0, 1]}
                        colors={['#8a9daf', '#d7e1e9']}
                        style={CommonStyles.refreshTextTooltip}>
                        <Text style={CommonStyles.quickScanTextOne}>
                          {i18n.t('scanpackItem.refreshTooltip')}
                        </Text>
                        <View style={CommonStyles.refreshTooltipContainerOne} />
                      </LinearGradient>
                    )}
                  </TouchableOpacity>
                  <View style={{height: 40, width: '100%'}} />
                </View>
              </ImageBackground>
            </ScrollView>
          </ImageBackground>
        </View>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    ssLabelData: state.order.ssLabelData,
    isLoadingActive: state?.order?.isLoadingActive,
    ordersList: state.order.list,
    searchOrder: state.scanpack,
    userInfo: state.user.userInfo,
    importOrder: state.importOrder,
    showNotification: state.menu.showNotification,
    isCodeFound: state.menu.isCodeFound,
    isOpen: state.menu.isOpen,
    bothSettings: state.user.bothSettings,
    purchaseLoading: state.order.purchaseLoading,
    orderList: state.saveLog,
  };
};

const mapDispatchToProps = {
  GetOrderList,
  SearchScanpackOrder,
  ShowNotification,
  UserInfo,
  ImportOrder,
  ImportOrderStatus,
  UpdateOrderStatus,
  ResetSearchOrderData,
  GetBothSettings,
  fetchUser
};
export default connect(mapStateToProps, mapDispatchToProps)(ScanPack);
